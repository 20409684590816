.footer{

    background-color: white;
    width:100%;
    position: relative;

    
}
.footer img{
    height: 1.5vw;
    min-height: 24px;
}
.footer-wrap{
    width:97%;
    margin:auto;
    margin-inline: 1.5%;
    display: flex;
    justify-content: space-between;

    gap:1.3%;
    align-items: center;
    
    height:50px;
    padding-top: 0.5vw;
    padding-bottom: 1.5vw;

}
.footer-wrap > div{
    max-width:24%;
    width: 24%;

}
.footer-col-2, .footer-col-3, .footer-col-4{
    display: flex;
    flex-direction: column;
    transform:translateY(4px);
    position: relative;
}
.footer-col-4 > a, .footer-col-4 > p{
    padding-left: 50%;
}
.footer-wrap > div > p{
    width: 100%;
    
}

.footer-col-2 a, .footer-col-3 a, .footer-col-4 a{
    cursor:pointer;
    width: max-content;
}
a, p{
    font-family: var(--font-family);
    font-weight:500;
    font-size: 16px;
}
.footer input{
    opacity: 0;
}
.footer label{
    user-select: none;
    cursor: pointer;
}
@media only screen and (max-width:1023px){
    .footer-wrap{
        width: 94%;
        margin-inline:3%;
        padding-bottom: 3svw;



            

    }
    .footer-wrap > div:first-child{
        width:26%
    }
    .footer-col-4 > a, .footer-col-4 > p{
        padding-left: 0%;
    }
.footer-wrap > div:last-child{
    width:76%
}

   
    .footer-col-2 {
        width: 0;
    }
    .footer-col-3 {
        width: 0;
    }
   
}

@media (max-width: 767px) {
    .footer-wrap{
        width: 92%;
        margin-inline:4%;
        height: max-content;
        padding-bottom: 4svw;
        flex-wrap: wrap;




    }
    .footer-wrap > div{
        width: 50% ;
        max-width: 49%;
    }
    .footer-col-3 {
        display: none;
    }
    .footer-col-1 {
        width: 100% !important;
        max-width: 100% !important;
    }
}