/* Base styles for the gallery grid */
.gallery-grid {
    display: grid;
    grid-gap: 1vw;

    margin: auto;
   
  }
  .album-heading{
    width: 75%;
    left: 25%;
    position: relative;
    pointer-events: all;
    
  }
  
  .div-reveal-element:has(> div > .album-heading){
    position: relative;
    z-index: 999;
  }
  .album-heading span{
    font-size: 3.5vw;
  }
  .album-heading .column-selector{
    margin-top:4.5svw;
    height: 2.5svw;
    display: flex;

    align-items: center;
    filter: grayscale(1);
  }
  .album-heading .column-selector input{
    margin-inline: .5em;
  }
  .album-heading .column-selector label:nth-child(1) input{
    margin-left: 0em;
  }
  .gallery-item .reveal-inner{
    background-color: #EEEEEE;
  }
  .gallery-wrap{
    margin-top:-2.5vw;
  }
  .gallery-wrap > .pin-spacer, .gallery-wrap .div-reveal-element{
    pointer-events: none;
  }
  /* 2 columns for tablet (768px to 1023px) */
  @media(max-width: 1023px) {
      .gallery-grid {
        grid-template-columns: repeat(2, 1fr) !important;
      }
      .gallery-wrap{
        margin-top:calc(-3svw - 20px - (3.5vw * 0.1));
      }
      .column-selector{
        display: none !important;
      }
      .album-heading{
        padding-bottom: 4svw;
      }
      .gallery-item{
        margin-bottom: 4svw;
      }
      .lightbox-image-container{
        width: 94vw !important;
      }
      .lightbox-details-container-bottom{
        bottom: 3vw !important;
      }
    }

  /* 1 column for mobile (less than 768px) */
  @media (max-width: 767px) {
    
    .gallery-grid {
      grid-template-columns: 1fr !important;
    }
    .lightbox-image-container{
      width: 92vw !important;
    }
    .lightbox-details-container-bottom{
      bottom: 4vw !important;
    }
  }
  

  
  /* Styling individual items in the gallery */
  .gallery-item {
    overflow: hidden;
    padding-bottom: 4em;
    transition: transform 0.3s ease;
    position: relative;
  }
  .gallery-item:has(.parallax-index){
    padding-bottom: 4em;
  }
  .gallery-item .parallax-index{
    position: absolute;
    bottom:0;
   
  }
  .gallery-item .image-container{
    cursor: zoom-in;
  }



  .lightbox{
    height: 100vh;
    width: 100%;
    position: relative;
  }
  .lightbox .lightbox-image-container{
    user-select: none;
    height: calc(100vh);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    align-items: center;
  }
  .lightbox .lightbox-image-container img{
    max-height: 80vh;
    max-width: 60vw;
  }
  .lightbox-details-container-top{
    position: fixed;
    top:2.5vw;
    z-index: 1;
  }
  .lightbox-details-container-bottom{
    position: absolute;
    bottom: 1.5vw;
    z-index: 1;
  }
