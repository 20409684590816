.archive-page{
    position: relative;
}
.album-item-wrap:has(.album-item:hover) .album-image{
    display: block !important;
}
.archive-page:has(.album-item:hover) > .text-reveal-element{
    display: none;
}
.archive-page:has(.album-item:hover) .album-item{
    opacity: 0.6;
}
.album-item:hover{
    opacity: 1 !important;
}
.album-image{
    max-height: calc(100vh - 10vw);
    object-fit: cover;
    object-position: center;
}
.album-image-wrap{
    position: fixed;
    right: 1.5vw;
    bottom: 1.5vw;
}
.album-image-wrap .album-image{
    max-width: 23vw;
}
.album-item-wrap > a, .album-item{
    width: max-content;
    display: block;
}
.archive-page > .text-reveal-element{
    position: fixed;
    bottom: 1.5vw;
    right: 1.5vw;
}
.archive-page > .div-reveal-element h2{
    font-size: 3.5vw;
    padding-bottom: 1em;
}
.album-items{
    display: flex;
    flex-direction: column;
    justify-content: end;
    min-height:calc(100vh - 15.5vw);
    height: max-content;
}
@media(max-width: 1023px) {
    .album-items{
        justify-content: start;
    }
    .archive-page > .text-reveal-element{
      display: none;
    }
    .album-image-wrap .album-image{
      display: none;
    }
  
  }

/* 1 column for mobile (less than 768px) */
@media (max-width: 767px) {
  .archive-page h2, .archive-page h2 span{
      font-size: 5vw !important;
  }
  
}