.minimal-header{
    position: fixed !important;
    top:0;
    left: 0;
    z-index: 9;
    min-width: 100%;

 


}
.minimal-header img{
    height: 1.5vw;
    min-height: 24px;
}
header > div{
    position: fixed !important;
}
.header-menu-link {
    display: flex;
    pointer-events: all;
}
.header-inner-content{
    background-color: white;
    padding-block: .5vw;

    width:97%;
    margin:auto;
    padding-inline: 1.5%;
    display: flex;
    justify-content: space-between;

    position: fixed;
    top:0;
    left: 0;


}
.header-menu-expanded{
    display: flex;
    flex-direction: column;
    position: absolute !important;
    top:0;
    right:0;
    opacity: 1;
}
.header-menu-expanded > p{
    cursor: pointer;
    margin:0px;
}

.header-menu-toggler{
    position: absolute;
    right:0;
    margin-top:48px;
    display: flex;
    align-items: center;
}
.header-menu-toggler *{
    font-family: var(--font-family);
    font-size: var(  --font-size-base);
    font-weight: 500;
    position: relative;
    
}
.header-menu-toggler > p{
    text-align: right;

    cursor: pointer;
}
.header-menu-toggler > p, .header-menu-expanded > p, .header-menu-toggler > a, .header-menu-expanded > a{
    display: flex;
    overflow: hidden;
}
.header-inner-content .header-logo, .header-inner-content .header-hamburger{
    display: flex;
    align-items: center;
    height: 100%;
}
.header-inner-content .header-logo{
    mix-blend-mode: difference;
    filter: invert(1);
}
.header-menu{
    position: fixed;
    bottom:0;
    width:92%;
    height: 100vh;
    margin-inline:4%;
    margin-bottom:5em;
    
    pointer-events: none;
    display: flex;
}
.header-menu > div{
    width: calc(50% - 1%);
}
.header-menu-button{
    transform:translatex(10px)
}

p{
    min-height: 20px;
    inline-size: 150px; 
    position: relative;
    pointer-events: all;
}
.header-logo > a{
    font-size: 0px;
}
@media(max-width: 1023px) {
 .header-inner-content{
   padding-inline: 3%;
   width: 94%;
   padding-top: 3vw;
   padding-bottom: 1.5vw;
 }
 
}
@media (max-width: 767px) {
.header-inner-content{
  padding-inline: 4%;
  width: 92%;
  padding-top: 4vw;
  padding-bottom: 2vw;
}
}