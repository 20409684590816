body{
  background-color: white;
  margin:0;
  overflow-x: hidden;


}
body.dark header, body.dark footer{
  filter: invert(1);
}
body.dark .app-wrap{
  background-color: black;
}
body.dark .app-wrap *{
  color: white;
}
body.dark header img, body.dark footer img{
  filter: brightness(0.1);
}
body.dark .gallery-item .reveal-inner{
  background-color: transparent;
}
*{
  font-family: var(--font-family);
}
img{
  filter: grayscale(1);
}
h1 span, h2 span{
  font-size: 3.5vw;
}
h3 span{
  font-size: 1.5vw;
}
a, p, span, h1, h2, h3, h4, h5, input{
  color: black;
  text-decoration: none;
  font-weight:500;
  margin:0;
  letter-spacing: -0.04em;

}
a, p, span, input{
  font-size: 16px;
  
}
label{
  cursor: pointer;
}
.app-wrap{
  width: 97%;
  padding-inline: 1.5%;
  min-height: 100vh;
  padding-top: 2.5vw;
  background-color: white;
}
.app-wrap:has(.lightbox){
  padding-top: 0vw;
}
.parallax-image-container, .lightbox-image-container, .text-reveal-element, .div-reveal-element  {
  clip-path: inset(0%) !important;
}
.page-transitioning .text-reveal-element{
  transition: all 780ms ease-in-out !important;
  clip-path: inset(0% 0% 100%) !important;
  transform: translateY(50%);
}
.page-transitioning .parallax-image-container, .page-transitioning .lightbox-image-container, .page-transitioning .div-reveal-element {
  transition: all 780ms ease-out !important;
  clip-path: inset(0% 0% 100%) !important;
}
@media(max-width: 1605px) {
  .app-wrap{
    padding-top: calc(1vw + 20px);
  }
}
@media(max-width: 1023px) {
  .app-wrap{
    padding-inline: 3%;
    width: 94%;
    padding-top:calc(4.5svw + 20px);
  }
}
@media (max-width: 767px) {
  .app-wrap{
    padding-inline: 4%;
    width: 92%;
    padding-top:calc(6svw + 20px);
  }
.reveal-motion-span{
  transform: translateY(0%) translateZ(0px) !important;
  opacity: 1 !important;
}
.reveal-motion-span{
  transform: translateY(0%) translateZ(0px) !important;
  opacity: 1 !important;
}
.reveal-inner{
  clip-path: inset(0%) !important;
  transition: 0s !important;
}
}